import {
  Avatar,
  Button,
  Card,
  Image,
  InputNumber,
  Popconfirm,
  Rate,
  Select,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  BellOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import stars from "../../asset/icons/star.svg";
import emptyStars from "../../asset/icons/emptyStar.svg";
import moment from "moment";
import { BsSearch } from "react-icons/bs";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

//render banner image

const RenderBannerImages = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <Card
      className="h-[86px] w-[172px] rounded-lg overflow-hidden shadow-lg border border-gray-300"
      bodyStyle={{ padding: 0, display: "flex", justifyContent: "center" }}
    >
      <Image
        height={86}
        width={172}
        style={{ aspectRatio: "172/86", objectFit: "cover", padding: 8 }}
        src={imageSrc}
        alt={value}
      />
    </Card>
  );
};

const RenderNumberInput = (value) => {
  const { data, onBlur } = value;

  return (
    <InputNumber
      className="w-20"
      value={data}
      controls={false}
      onBlur={(e) => onBlur(e.target.value)}
      placeholder="Rank"
    />
  );
};

const RenderDateInLocal = (value) => {
  const localDate = value ? (
    moment(value).local().format("DD-MM-YYYY hh:mm:ss A")
  ) : (
    <>-</>
  );
  return <div>{localDate}</div>;
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to Delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

const RenderTransactionMode = (value) => {
  return (
    <span
      className={`${value === "Deposit" ? "text-green-600" : "text-red-600"}`}
    >
      {value}
    </span>
  );
};

const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Approve this Request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this Request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#dc2626" }} className="ml-2 ">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to Delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
export const RenderEditButton = (value) => {
  const { id, onClick, disabled = false } = value;
  return (
    <Button
      type="primary"
      disabled={disabled}
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {} } = value;
  return (
    <Button
      onClick={() => {
        // console.log('click');
        onClick(id);
      }}
      type="primary"
    >
      <EyeOutlined />
    </Button>
  );
};

const RenderDoubleButton = (value) => {
  const { id, onViewMore, onAnalyzeUser } = value;
  return (
    <div>
      <Button onClick={() => onViewMore(id)} type="primary">
        <EyeOutlined />
      </Button>
      <Button type="primary" className="ml-2 " onClick={() => onAnalyzeUser()}>
        <BsSearch twoToneColor="#dc2626" />
      </Button>
    </div>
  );
};

// Render View Button
const RenderNotificationButton = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {} } = value;
  return (
    <Popconfirm
      onConfirm={() => onClick(id)}
      title="Are you sure you want to send a notification?"
    >
      <Button type="primary">
        <BellOutlined />
      </Button>
    </Popconfirm>
  );
};

// Render Images
const UserImageRanders = (value) => {
  return (
    <div>
      {value?.image ? (
        <Image
          width={50}
          height={50}
          className="rounded-full object-cover"
          src={value?.image}
          alt={value}
        />
      ) : (
        <Avatar className={`flex items-center w-12 h-12 text-xl bg-gray-600`}>
          {value?.name?.charAt(0).toUpperCase()}
        </Avatar>
      )}
    </div>
  );
};

// Render Images
const ImageRanders = (value) => {
  // console.log(value);
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

const LinkRenderer = (value) => {
  return value ? (
    <a
      href={value}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary"
    >
      {value}
    </a>
  ) : (
    <p className="text-red-400">Link not found</p>
  );
};

// Toggle Button
export const RenderToggleButton = (value) => {
  // console.log(value);
  const { checked, id, onClick, size = "default", isDisable = false } = value;
  return (
    <div>
      <Switch
        size={size}
        onChange={() => {
          onClick(id, checked);
        }}
        checked={!checked}
        disabled={isDisable}
      />
    </div>
  );
};

// QR Button
const RenderQrButton = (value = {}) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      Generate QR
    </Button>
  );
};

// Ratings
const RenderRating = (value) => {
  // console.log(value);
  const { ratings } = value;
  return (
    <div>
      {/* {ratings}&nbsp;< StarFilled /> */}

      <Rate
        disabled
        allowHalf
        value={ratings}
        character={({ index }) => {
          if (index < ratings) {
            return <img src={stars} />;
          } else {
            return <img src={emptyStars} />;
          }
        }}
      />
    </div>
  );
};

const RenderButtonWithIcon = (value) => {
  // console.log(value);
  const { id = null, onClick = () => {}, icon = null } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      {icon}
    </Button>
  );
};
export const loginRoot = "/";
export const appRoot = "/app";

const CONSTANTS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  GETMe: null,
  TABLE: {
    ROLE_MANAGEMENT: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
      },

      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },

      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
        width: 180,
      },
      {
        title: "Role",
        dataIndex: "role",
        width: 100,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    TASK_CATEGORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },

      {
        title: "Task Category",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 250,
      },
      {
        title: "Ranking",
        dataIndex: "ranking",
        sorter: (a, b) => alphanumericSort(a, b, "rankingNumber"),
        render: RenderNumberInput,
        width: 50,
      },
      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    TESTIMONIALS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "userImage",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "userName"),
        width: 100,
      },
      {
        title: "Message",
        dataIndex: "message",
        sorter: (a, b) => alphanumericSort(a, b, "message"),
        width: 200,
      },
      {
        title: "Ratings",
        dataIndex: "rating",
        render: RenderRating,
        width: 170,
      },
      {
        title: "Block",
        dataIndex: "block",
        render: RenderToggleButton,
        width: 90,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 70,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    FAQ: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Ranking",
        dataIndex: "ranking",
        sorter: (a, b) => alphanumericSort(a, b, "rankingNumber"),
        render: RenderNumberInput,
        width: 100,
      },
      {
        title: "Question",
        dataIndex: "question",
        sorter: (a, b) => alphanumericSort(a, b, "question"),
        width: 200,
      },
      {
        title: "Answer",
        dataIndex: "answer",
        sorter: (a, b) => alphanumericSort(a, b, "answer"),
        width: 200,
      },
      {
        title: "Block",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 90,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    BRAND: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "logo",
        render: ImageRanders,
        width: 50,
      },
      {
        title: "Brand Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 300,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },

      {
        title: "Block",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 50,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    TASKSTEP: [
      {
        title: "Step",
        dataIndex: "stepNumberField",
        sorter: (a, b) => alphanumericSort(a, b, "stepRank"),
        render: RenderNumberInput,
        width: 50,
      },
      {
        title: "Step Name",
        dataIndex: "name",
        width: 50,
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 75,
      },
      {
        title: "Goal",
        dataIndex: "goalName",
        width: 50,
      },
      {
        title: "Waiting Time",
        dataIndex: "waitingTimeBTwoStep",
        width: 40,
      },
      {
        title: "Waiting Message",
        dataIndex: "waitingMessage",
        width: 50,
      },
      {
        title: "Error Message",
        dataIndex: "errorMessage",
        width: 50,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        width: 40,
      },
      {
        title: "Submission Needed",
        dataIndex: "isSubNeeded",
        render: RenderToggleButton,
        // filters: [
        //   {
        //     id: "Unable Banner",
        //     label: "UnBlocked",
        //     text: "UnBlocked",
        //     value: 1,
        //   },
        //   {
        //     id: "Disable Banner",
        //     label: "Blocked",
        //     text: "Blocked",
        //     value: 0,
        //   },
        // ],
        // filterMultiple: false,
        // onFilter: (value, record) => true,
        width: 40,
      },
      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Delete",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    BANNER: [
      {
        title: "Ranking",
        dataIndex: "ranking",
        sorter: (a, b) => alphanumericSort(a, b, "rankingNumber"),
        render: RenderNumberInput,
        width: 50,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: RenderBannerImages,
        width: 100,
      },
      {
        title: "URL",
        dataIndex: "link",
        render: LinkRenderer,
        width: 100,
      },
      {
        title: "Block",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        filters: [
          {
            id: "Unable Banner",
            label: "UnBlocked",
            text: "UnBlocked",
            value: 1,
          },
          {
            id: "Disable Banner",
            label: "Blocked",
            text: "Blocked",
            value: 0,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => true,
        width: 50,
      },
      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Delete",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    AD: [
      {
        title: "Ranking",
        dataIndex: "ranking",
        sorter: (a, b) => alphanumericSort(a, b, "rankingNumber"),
        render: RenderNumberInput,
        width: 50,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: RenderBannerImages,
        width: 100,
      },
      {
        title: "URL",
        dataIndex: "link",
        render: LinkRenderer,
        width: 100,
      },
      {
        title: "Type",
        dataIndex: "type",
        // render: LinkRenderer,
        width: 50,
      },
      {
        title: "Block",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        filters: [
          {
            id: "Unable Banner",
            label: "UnBlocked",
            text: "UnBlocked",
            value: 1,
          },
          {
            id: "Disable Banner",
            label: "Blocked",
            text: "Blocked",
            value: 0,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => true,
        width: 50,
      },
      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Delete",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    BRAND_TASK: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Category",
        dataIndex: "category",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task Heading",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Label",
        dataIndex: "label",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Status",
        dataIndex: "status",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },
      // {
      //   title: "Edit",
      //   dataIndex: "editTableRow",
      //   render: RenderEditButton,
      //   width: 50,
      // },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    TASKAPPROVAL: [
      {
        title: "Click Id",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "User Image",
        dataIndex: "image",
        render: UserImageRanders,
        width: 50,
      },
      {
        title: "User Name",
        dataIndex: "name",
        width: 70,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        width: 70,
      },
      {
        title: "City",
        dataIndex: "city",
        width: 70,
      },
      {
        title: "Task ID",
        dataIndex: "taskId",
        sorter: (a, b) => alphanumericSort(a, b, "taskId"),
        width: 50,
      },
      {
        title: "Task Name",
        dataIndex: "taskName",
        // sorter: (a, b) => alphanumericSort(a, b, "taskId"),
        width: 70,
      },
      {
        title: "Task Details",
        dataIndex: "taskDetails",
        render: RenderViewButton,
        width: 50,
      },
      {
        title: "Submission Details",
        dataIndex: "proofOfWork",
        render: RenderViewButton,
        width: 50,
      },
      {
        title: "Select Action",
        dataIndex: "status",
        width: 70,
      },
      {
        title: "Message",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
    ],
    TASK: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Brand Name",
        dataIndex: "brandName",
        // filters: [],
        // filterMultiple: false,
        // onFilter: (value, record) => true,
        // filterSearch: true,
        width: 100,
      },

      {
        title: "Category",
        dataIndex: "category",
        // filters: [],
        // filterMultiple: false,
        // onFilter: (value, record) => true,
        // filterSearch: true,
        width: 100,
      },
      {
        title: "Task Heading",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Priority",
        dataIndex: "priority",
        sorter: (a, b) => alphanumericSort(a, b, "priority"),
        width: 70,
      },
      {
        title: "Label",
        dataIndex: "label",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 120,
      },
      {
        title: "Task Status",
        dataIndex: "status",
        render: RenderToggleButton,
        filters: [
          {
            id: "Active",
            label: "Active",
            text: "Active",
            value: "Active",
          },
          {
            id: "Inactive",
            label: "Inactive",
            text: "Inactive",
            value: "Inactive",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => true,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Spin",
        dataIndex: "completionSpin",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 50,
      },
      {
        title: "Review Days",
        dataIndex: "reviewDays",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 70,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 70,
      },
      {
        title: "Best Project",
        dataIndex: "isBestProject",
        render: RenderToggleButton,
        width: 70,
      },
      {
        title: "Notification",
        dataIndex: "notification",
        render: RenderNotificationButton,
        width: 80,
      },
      {
        title: "Edit",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 70,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 70,
      },
    ],
    USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "userImage",
        render: UserImageRanders,
        width: 70,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 70,
      },
      {
        title: "User Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 140,
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: (a, b) => alphanumericSort(a, b, "city"),
        width: 140,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 130,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
        width: 130,
      },
      {
        title: "Identify",
        dataIndex: "identify",
        width: 100,
      },

      {
        title: "Affiliate",
        dataIndex: "affiliate",
        render: RenderToggleButton,
        width: 80,
      },
      {
        title: "Refer & Earn",
        dataIndex: "referAndEarnEnable",
        render: RenderToggleButton,
        width: 80,
      },
      {
        title: "Block",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 70,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 70,
      },
    ],
    USER_VERIFICATION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "Image",
        dataIndex: "userImage",
        render: UserImageRanders,
        width: 50,
      },
      {
        title: "User Details",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },
      {
        title: "User Id",
        dataIndex: "id",
        sorter: (a, b) => alphanumericSort(a, b, "id"),
        width: 50,
      },
      {
        title: "User Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 140,
      },
      {
        title: "Documents",
        dataIndex: "document",
        render: RenderViewButton,
        width: 50,
      },
      {
        title: "Identify",
        dataIndex: "identify",
        width: 50,
      },
      {
        title: "Select Action",
        dataIndex: "isGovermentIdVerified",
        width: 70,
        filters: [
          {
            id: "Not Verified",
            label: "Not Verified",
            text: "Not Verified",
            value: 0,
          },
          {
            id: "Pending",
            label: "Pending",
            text: "Pending",
            value: 1,
          },
          {
            id: "Verified",
            label: "Verified",
            text: "Verified",
            value: 2,
          },
          {
            id: "Failed",
            label: "Failed",
            text: "Failed",
            value: 3,
          },
        ],
        // filterSearch: true,
        filterMultiple: false,
        onFilter: (value, record) => true,
      },
    ],
    WITHDRAWAL_REQUEST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "User Image",
        dataIndex: "userImage",
        render: UserImageRanders,
        width: 50,
      },
      {
        title: "User Name",
        dataIndex: "name",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "User Details",
        dataIndex: "view",
        render: RenderDoubleButton,
        width: 70,
      },
      {
        title: "Block",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        width: 50,
      },
      // {
      //   title: "Identify",
      //   dataIndex: "identify",
      //   width: 50,
      // },
      // {
      //   title: "Device ID",
      //   dataIndex: "deviceId",
      //   // render: ImageRanders,
      //   width: 100,
      // },
      {
        title: "Total Task",
        dataIndex: "totalTask",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Score",
        dataIndex: "score",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Root Device",
        dataIndex: "isRootDevice",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Referral Count",
        dataIndex: "referralCount",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Same Device Account",
        dataIndex: "sameDeviceIdCount",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: RenderDateInLocal,
        width: 100,
      },
      {
        title: "Withdrawal Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "request",
        render: RenderAcceptRejectButton,
        width: 70,
      },
    ],
    NOTIFICATION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "Task Name",
        dataIndex: "taskName",
        width: 100,
      },
      {
        title: "Schedule TIme",
        dataIndex: "localTime",
        width: 50,
      },
      {
        title: "Target User",
        dataIndex: "targetUser",
        width: 100,
      },
      {
        title: "Status",
        dataIndex: "status",
        render: RenderToggleButton,
        width: 50,
      },
      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 50,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 50,
      },
    ],
    USER_DEVICE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "Device Id",
        dataIndex: "deviceId",
        sorter: (a, b) => alphanumericSort(a, b, "deviceId"),
        width: 100,
      },
      {
        title: "User Count",
        dataIndex: "userCount",
        sorter: (a, b) => alphanumericSort(a, b, "userCount"),
        width: 50,
      },
      {
        title: "View User",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },
      {
        title: "Block",
        dataIndex: "blocked",
        render: RenderToggleButton,
        // filters: [
        //   {
        //     id: "Unable Banner",
        //     label: "UnBlocked",
        //     text: "UnBlocked",
        //     value: 1,
        //   },
        //   {
        //     id: "Disable Banner",
        //     label: "Blocked",
        //     text: "Blocked",
        //     value: 0,
        //   },
        // ],
        // filterMultiple: false,
        // onFilter: (value, record) => true,
        width: 50,
      },
    ],
    USERLIST_DEVICE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 20,
      },
      {
        title: "Image",
        dataIndex: "userImage",
        render: UserImageRanders,
        width: 20,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 30,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        width: 30,
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 40,
      },
      {
        title: "City",
        dataIndex: "city",
        width: 20,
      },
      {
        title: "Referral Code",
        dataIndex: "myReferralCode",
        width: 30,
      },
      {
        title: "Reference Code",
        dataIndex: "referenceCode",
        width: 30,
      },
      {
        title: "Device  Type",
        dataIndex: "deviceType",
        width: 20,
      },
      {
        title: "Score",
        dataIndex: "score",
        width: 20,
      },
      {
        title: "Block",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        width: 20,
      },
      {
        title: "Wallet Balance",
        dataIndex: "walletBalance",
        width: 20,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 20,
      },
    ],
    WITHDRAWAL_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "User Image",
        dataIndex: "userImage",
        render: UserImageRanders,
        width: 50,
      },
      {
        title: "User Name",
        dataIndex: "name",
        // render: ImageRanders,
        width: 50,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        // render: ImageRanders,
        width: 50,
      },
      // {
      //   title: "User Details",
      //   dataIndex: "view",
      //   render: RenderViewButton,
      //   width: 50,
      // },
      {
        title: "Identify",
        dataIndex: "identify",
        width: 50,
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: RenderDateInLocal,
        width: 70,
      },
      {
        title: "Withdrawal Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
        width: 50,
      },
      {
        title: "Status",
        dataIndex: "statusD",
        // sorter: (a, b) => alphanumericSort(a, b, "amount"),
        width: 50,
      },
    ],
    USER_TASK: [
      {
        title: "Click Id",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Task Id",
        dataIndex: "taskId",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task Name",
        dataIndex: "task",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Applied Date",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: RenderDateInLocal,
        width: 150,
      },
      {
        title: "Submission Date",
        dataIndex: "submissionDateTime",
        render: RenderDateInLocal,
        sorter: (a, b) => alphanumericSort(a, b, "submissionDateTime"),
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        filters: [
          {
            id: "InProgress",
            label: "InProgress",
            text: "In-Progress",
            value: "InProgress",
          },
          {
            id: "Pending",
            label: "Pending",
            text: "Pending",
            value: "Pending",
          },
          {
            id: "Accepted",
            label: "Accepted",
            text: "Accepted",
            value: "Accepted",
          },
          {
            id: "Rework",
            label: "Rework",
            text: "Rework",
            value: "Rework",
          },
          {
            id: "Resubmit",
            label: "Resubmit",
            text: "Resubmit",
            value: "Resubmit",
          },
          {
            id: "Reject",
            label: "Reject",
            text: "Reject",
            value: "Reject",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => true,
        width: 100,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "View Task",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },
    ],
    USER_TRANSACTION: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "Transaction ID",
        dataIndex: "paymentId",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        render: RenderDateInLocal,
        width: 100,
      },
      {
        title: "Transaction Type",
        dataIndex: "mode",
        render: RenderTransactionMode,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Transaction Details",
        dataIndex: "source",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 50,
      },
      {
        title: "Spin",
        dataIndex: "spin",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 50,
      },
      {
        title: "Status",
        dataIndex: "statusD",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
    ],
    REFERRED_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "Name",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Email",
        dataIndex: "email",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: RenderDateInLocal,
        width: 100,
      },
      {
        title: "Device Id",
        dataIndex: "deviceId",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Block",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        width: 50,
      },
      {
        title: "Block Reason",
        dataIndex: "blockReason",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 150,
      },
      {
        title: "Referral Earning (%) From Task",
        dataIndex: "commisionProgress",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Wallet Balance",
        dataIndex: "walletBalance",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 50,
      },
      {
        title: "View User",
        dataIndex: "view",
        render: RenderViewButton,
        width: 50,
      },
    ],
    REFERRED_USER_TASK: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 50,
      },
      {
        title: "User Name",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task Name",
        dataIndex: "taskName",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task Amount",
        dataIndex: "taskAmount",
        // sorter: (a, b) => alphanumericSort(a, b, "taskAmount"),
        width: 50,
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: [
          {
            id: "InProgress",
            label: "InProgress",
            text: "In-Progress",
            value: "InProgress",
          },
          {
            id: "Pending",
            label: "Pending",
            text: "Pending",
            value: "Pending",
          },
          {
            id: "Accepted",
            label: "Accepted",
            text: "Accepted",
            value: "Accepted",
          },
          {
            id: "Rework",
            label: "Rework",
            text: "Rework",
            value: "Rework",
          },
          {
            id: "Resubmit",
            label: "Resubmit",
            text: "Resubmit",
            value: "Resubmit",
          },
          {
            id: "Reject",
            label: "Reject",
            text: "Reject",
            value: "Reject",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => true,
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Applied Date",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
        render: RenderDateInLocal,
        width: 100,
      },
      {
        title: "Submission Date",
        dataIndex: "submissionDateTime",
        sorter: (a, b) => alphanumericSort(a, b, "submissionDateTime"),
        render: RenderDateInLocal,
        width: 100,
      },
      {
        title: "Commission Amount",
        dataIndex: "commissionAmount",
        // sorter: (a, b) => alphanumericSort(a, b, "commissionAmount"),
        width: 100,
      },
    ],
    AFFILIATE_HISTORY: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Image",
        dataIndex: "image",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Email",
        dataIndex: "email",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task name",
        dataIndex: "taskName",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Task Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Status",
        dataIndex: "status",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Created At",
        dataIndex: "createdDate",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
      {
        title: "Wallet Balance",
        dataIndex: "walletBalanceA",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },
    ],
    TASK_USERS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },

      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 70,
      },
      {
        title: "User ID",
        dataIndex: "userId",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 100,
      },

      {
        title: "User Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 140,
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
        width: 130,
      },
      {
        title: "Proof of work",
        dataIndex: "view",
        render: RenderViewButton,
        width: 70,
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 70,
      },

      {
        title: "Edit",
        dataIndex: "editTableRow",
        render: RenderEditButton,
        width: 70,
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    ROLE_MANAGEMENT_MODAL: [
      {
        no: 0,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Name",
        required: true,
        width: 24,
        labelwidth: 24,
      },

      {
        no: 1,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter a Email",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Password",
        name: "password",
        id: "password",
        type: "password",
        placeholder: "Enter a Password",
        required: true,
        width: 12,
        labelwidth: 24,
      },
    ],
    EDIT_ROLE_MANAGEMENT_MODAL: [
      {
        no: 0,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Name",
        required: true,
        width: 24,
        labelwidth: 12,
      },

      {
        no: 1,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter a Email",
        required: true,
        width: 12,
        labelwidth: 12,
      },
      {
        no: 2,
        Label: "Password",
        name: "passwordD",
        id: "passwordD",
        type: "password",
        placeholder: "Enter a Password",
        required: false,
        width: 12,
        labelwidth: 24,
      },
    ],
    TASK_CATEGORY_MODAL: [
      {
        no: 0,
        Label: "Category Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Category Name",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    USER_BLOCK_FORM: [
      {
        no: 0,
        Label: "Block Reason",
        name: "blockReason",
        id: "blockReason",
        type: "textarea",
        placeholder: "Enter a Reason",
        required: false,
        width: 24,
        labelwidth: 24,
      },
    ],
    TESTIMONIAL_MODAL: [
      {
        no: 0,
        Label: "Name",
        name: "userName",
        id: "userName",
        type: "text",
        placeholder: "Enter a User Name",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 1,
        Label: "Profile",
        name: "userImage",
        id: "userImage",
        type: "file",
        placeholder: "Enter a Profile",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Ratings",
        name: "starRating",
        id: "starRating",
        type: "number",
        placeholder: "Enter a Ratings",
        required: true,
        rule: {
          validator: (_, value) => {
            if (value < 6 && value > -1) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Please Enter a valid rating between 0 to 5")
            );
          },
        },
        width: 24,
        labelwidth: 24,
      },
      {
        no: 3,
        Label: "Message",
        name: "message",
        id: "message",
        type: "textarea",
        placeholder: "Enter a Message",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    EDIT_TESTIMONIAL_MODAL: [
      {
        no: 0,
        Label: "Name",
        name: "userName",
        id: "userName",
        type: "text",
        placeholder: "Enter a User Name",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 1,
        Label: "Profile",
        name: "userImage",
        id: "userImage",
        type: "file",
        placeholder: "Enter a Profile",
        required: false,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Ratings",
        name: "starRating",
        id: "starRating",
        type: "number",
        placeholder: "Enter a Ratings",
        required: true,
        rule: {
          validator: (_, value) => {
            if (value < 6 && value > -1) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Please Enter a valid rating between 0 to 5")
            );
          },
        },
        width: 24,
        labelwidth: 24,
      },
      {
        no: 3,
        Label: "Message",
        name: "message",
        id: "message",
        type: "textarea",
        placeholder: "Enter a Message",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    GENEREL_NOTIFICATION: [
      {
        no: 0,
        id: "taskId",
        type: "select",
        // mode: "multiple",
        placeholder: "Select Project",
        Label: "Select Project",
        name: "taskId",
        required: false,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 1,
        id: "status",
        type: "select",
        mode: "multiple",
        placeholder: "Target User",
        Label: "Target User",
        name: "status",
        required: false,
        width: 24,
        labelwidth: 24,
        option: [
          { Label: "All", value: -1, id: "All" },
          { Label: "In-Progress", value: 1, id: "InProgress" },
          { Label: "Pending", value: 2, id: "Pending" },
          { Label: "Accepted", value: 3, id: "Accepted" },
          { Label: "Rework", value: 4, id: "Rework" },
          { Label: "Reject", value: 5, id: "Reject" },
        ],
      },
      {
        no: 2,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 3,
        Label: "Message",
        name: "body",
        id: "body",
        type: "textarea",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    SCHEDULE_NOTIFICATION: [
      {
        no: 0,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        acceptFormat: "image/png, image/jpeg",
        required: false,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 1,
        id: "taskId",
        type: "select",
        // mode: "multiple",
        placeholder: "Select Project",
        Label: "Select Project",
        name: "taskId",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 2,
        id: "targetUsers",
        type: "select",
        mode: "multiple",
        placeholder: "Target User",
        Label: "Target User",
        name: "targetUsers",
        required: true,
        width: 24,
        labelwidth: 24,
        option: [
          { Label: "All", value: "-1", id: -1 },
          { Label: "In-Progress", value: "1", id: 1 },
          { Label: "Pending", value: "2", id: 2 },
          { Label: "Accepted", value: "3", id: 3 },
          { Label: "Rework", value: "4", id: 4 },
          { Label: "Reject", value: "5", id: 5 },
        ],
      },
      {
        no: 3,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 4,
        Label: "Message",
        name: "message",
        id: "message",
        type: "textarea",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 5,
        Label: "Time Schedule",
        name: "time",
        id: "time",
        type: "time",
        format: "HH:mm",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    USER_NOTIFICATION: [
      {
        no: 0,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 1,
        Label: "Message",
        name: "body",
        id: "body",
        type: "textarea",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    TASK_MODAL: [
      {
        no: 0,
        id: "name",
        type: "text",
      },
      {
        no: 1,
        id: "brandId",
        type: "select",
      },
      {
        no: 2,
        id: "completionAmount",
        type: "number",
      },
      {
        no: 3,
        id: "completionSpin",
        type: "number",
      },
      {
        no: 4,
        id: "taskCategoryId",
        type: "select",
      },
      {
        no: 5,
        id: "expireDate",
        type: "text",
      },
      {
        no: 6,
        id: "completionTime",
        type: "number",
      },
      {
        no: 7,
        id: "priority",
        type: "number",
      },
      {
        no: 8,
        id: "helpLink",
        type: "text",
      },
      {
        no: 9,
        id: "isRegionBased",
        type: "text",
      },
      {
        no: 10,
        id: "regions",
        type: "text",
      },
      {
        no: 11,
        id: "availableFileType",
        type: "text",
      },
      {
        no: 12,
        id: "description",
        type: "text",
      },
      {
        no: 13,
        id: "termAndCondition",
        type: "text",
      },
      {
        no: 14,
        id: "visibleOnApp",
        type: "text",
      },
      {
        no: 15,
        id: "trainingUrl",
        type: "text",
      },
      {
        no: 16,
        id: "banner",
        type: "file",
      },
      {
        no: 17,
        id: "isCompleteEdit",
        type: "text",
      },
      {
        no: 18,
        id: "featuredImage",
        type: "file",
      },
      {
        no: 19,
        id: "trackUrl",
        type: "text",
      },
      {
        no: 20,
        id: "message",
        type: "text",
      },
      {
        no: 21,
        id: "reviewDays",
        type: "number",
      },
      {
        no: 22,
        id: "extendReviewDays",
        type: "number",
      },
      {
        no: 23,
        id: "endGoal",
        type: "text",
      },
      {
        no: 24,
        id: "defaultNumberOfDaysForResubmitOnReject",
        type: "number",
      },
      {
        no: 25,
        id: "defaultAdminMessageOnReject",
        type: "text",
      },
      {
        no: 26,
        id: "percentageReferralEarning",
        type: "number",
      },
    ],
    TASK_STEP: [
      {
        no: 1,
        Label: "Step Name",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "text",
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 3,
        Label: "Goal Value",
        name: "goalName",
        id: "goalName",
        type: "text",
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 4,
        Label: "Step Amount",
        name: "amount",
        id: "amount",
        type: "number",
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 5,
        Label: "Waiting Time",
        name: "waitingTimesStep",
        id: "waitingTimesStep",
        type: "number",
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 6,
        Label: "Waiting Time Formate",
        name: "timeFormate",
        id: "timeFormate",
        type: "select",
        placeholder: "Select type",
        option: [
          { Label: "Minutes", value: "Minutes", id: 1 },
          { Label: "Hours", value: "Hours", id: 2 },
          { Label: "Days", value: "Days", id: 3 },
        ],
        required: true,
        width: 8,
        labelwidth: 8,
      },
      {
        no: 7,
        Label: "Is submission Needed (Is screenshot required)",
        name: "isPOWNeeded",
        id: "isPOWNeeded",
        type: "switch",
        width: 24,
        labelwidth: 24,
      },
      {
        no: 8,
        Label: "Waiting Message",
        name: "waitingMessage",
        id: "waitingMessage",
        type: "textarea",
        width: 12,
        labelwidth: 12,
      },
      {
        no: 9,
        Label: "Error Message",
        name: "errorMessage",
        id: "errorMessage",
        type: "textarea",
        width: 12,
        labelwidth: 12,
      },
      {
        no: 10,
        Label: "Notification Title (Hint: {userName} {taskName})",
        name: "notificationTitle",
        id: "notificationTitle",
        preview: true,
        type: "textarea",
        width: 12,
        labelwidth: 12,
      },
      {
        no: 11,
        Label: "Notification Message (Hint: {userName} {taskName})",
        name: "notificationBody",
        id: "notificationBody",
        preview: true,
        type: "textarea",
        width: 12,
        labelwidth: 12,
      },
    ],
    FAQ_MODAL: [
      {
        no: 0,
        Label: "Question",
        name: "question",
        id: "question",
        type: "text",
        placeholder: "Enter a Question",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Answer",
        name: "answer",
        id: "answer",
        type: "textarea",
        placeholder: "Enter a Answer",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    BANNER_MODAL: [
      {
        no: 0,
        Label: "Banner Image",
        name: "image",
        id: "image",
        // imageId: "organizationImage-pre",
        type: "image-crop-upload",
        acceptFormat:
          "image/png, image/jpeg, image/jpg, image/webp, image/tiff",
        placeholder: "",
        required: true,
        aspectRatio: 1.89 / 1,
        width: 4,
        labelwidth: 4,
      },
      {
        no: 1,
        Label: "URL",
        name: "link",
        id: "link",
        type: "url",
        required: false,
        width: 14,
        labelwidth: 14,
      },
    ],
    BANNER_EDIT_MODAL: [
      {
        no: 0,
        Label: "Banner Image",
        name: "image",
        id: "image",
        // imageId: "organizationImage-pre",
        type: "image-crop-upload",
        acceptFormat:
          "image/png, image/jpeg, image/jpg, image/webp, image/tiff",
        placeholder: "",
        required: false,
        aspectRatio: 1.89 / 1,
        width: 4,
        labelwidth: 4,
      },
      {
        no: 1,
        Label: "URL",
        name: "link",
        id: "link",
        type: "url",
        required: false,
        width: 14,
        labelwidth: 14,
      },
    ],
    AD_MODAL: [
      {
        no: 0,
        Label: "Type",
        name: "type",
        id: "type",
        type: "select",
        placeholder: "Select type",
        option: [
          { Label: "One Time", value: "One Time", id: 1 },
          { Label: "Iterable", value: "Iterable", id: 2 },
        ],
        required: true,
        width: 9,
        labelwidth: 9,
      },
      {
        no: 1,
        Label: "URL",
        name: "link",
        id: "link",
        type: "url",
        required: false,
        width: 9,
        labelwidth: 9,
      },
      {
        no: 2,
        Label: "Ad Image",
        name: "image",
        id: "image",
        // imageId: "organizationImage-pre",
        type: "image-crop-upload",
        acceptFormat:
          "image/png, image/jpeg, image/jpg, image/webp, image/tiff",
        placeholder: "",
        required: true,
        aspectRatio: 0.66,
        width: 6,
        labelwidth: 6,
      },
    ],
    AD_EDIT_MODAL: [
      {
        no: 0,
        Label: "Type",
        name: "type",
        id: "type",
        type: "select",
        placeholder: "Select type",
        option: [
          { Label: "One Time", value: "One Time", id: 1 },
          { Label: "Iterable", value: "Iterable", id: 2 },
        ],
        required: true,
        width: 9,
        labelwidth: 9,
      },
      {
        no: 1,
        Label: "URL",
        name: "link",
        id: "link",
        type: "url",
        required: false,
        width: 9,
        labelwidth: 9,
      },
      {
        no: 2,
        Label: "Ad Image",
        name: "image",
        id: "image",
        // imageId: "organizationImage-pre",
        type: "image-crop-upload",
        acceptFormat:
          "image/png, image/jpeg, image/jpg, image/webp, image/tiff",
        placeholder: "",
        required: false,
        aspectRatio: 0.66,
        width: 6,
        labelwidth: 6,
      },
    ],
    TASK_APPROVAL_MESSAGE: [
      {
        no: 0,
        Label: "Message",
        name: "adminMessage",
        id: "adminMessage",
        type: "textarea",
        placeholder: "Enter message",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    TASK_APPROVAL_MODAL: [
      {
        no: 0,
        Label: "Rating",
        name: "rating",
        id: "rating",
        type: "number",
        placeholder: "Enter rating",
        required: true,
        width: 24,
        labelwidth: 24,
      },
    ],
    TASK_REJECT_MODAL: [
      {
        no: 0,
        Label: "Message",
        name: "adminMessage",
        id: "adminMessage",
        type: "textarea",
        placeholder: "Enter message",
        required: true,
        width: 12,
        labelwidth: 12,
      },
      {
        no: 1,
        Label: "Number of days for resubmit",
        name: "numberOfDaysForResubmit",
        id: "numberOfDaysForResubmit",
        type: "number",
        placeholder: "Enter number of Days",
        required: false,
        width: 12,
        labelwidth: 12,
      },
    ],
    TASK_REMOVE_MODAL: [
      {
        no: 0,
        Label: "Message",
        name: "adminMessage",
        id: "adminMessage",
        type: "textarea",
        placeholder: "Enter message",
        required: true,
        width: 12,
        labelwidth: 12,
      },
      {
        no: 1,
        Label: "Number of days for resubmit",
        name: "numberOfDaysForResubmit",
        id: "numberOfDaysForResubmit",
        type: "number",
        placeholder: "Enter number of Days",
        required: true,
        width: 12,
        labelwidth: 12,
      },
    ],
    BRAND_MODAL: [
      {
        no: 0,
        Label: "Brand Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Brand Name",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 1,
        Label: "Priority",
        name: "ranking",
        id: "ranking",
        type: "number",
        placeholder: "",
        required: false,
        width: 6,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Brand Logo",
        name: "logo",
        id: "logo",
        type: "file",
        placeholder: "Enter a Answer",
        required: true,
        width: 6,
        labelwidth: 24,
      },
    ],
    EDIT_BRAND_MODAL: [
      {
        no: 0,
        Label: "Brand Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Brand Name",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 1,
        Label: "Priority",
        name: "ranking",
        id: "ranking",
        type: "number",
        placeholder: "",
        required: false,
        width: 6,
        labelwidth: 24,
      },
      {
        no: 3,
        Label: "Brand Logo",
        name: "logo",
        id: "logo",
        type: "file",
        placeholder: "Enter a Answer",
        required: false,
        width: 6,
        labelwidth: 24,
      },
    ],
    EDIT_USER_MODAL: [
      {
        no: 0,
        Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter a Brand Name",
        required: true,
        width: 24,
        labelwidth: 24,
      },
      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter a Mobile Number",
        required: true,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 3,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter a Email",
        required: false,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 4,
        Label: "Date of Birth",
        name: "DOB",
        id: "DOB",
        type: "date",
        placeholder: "Enter a Date of Birth",
        format: "DD-MM-YYYY",
        required: true,
        width: 8,
        labelwidth: 24,
      },
      {
        no: 5,
        Label: "Gender",
        name: "gender",
        id: "gender",
        type: "select",
        placeholder: "Enter a Gender",
        option: [
          { Label: "Male", value: "male", id: 1 },
          { Label: "Female", value: "female", id: 2 },
        ],
        required: true,
        width: 8,
        labelwidth: 24,
      },
      {
        no: 6,
        Label: "My Referral Code",
        name: "myReferralCode",
        id: "myReferralCode",
        type: "text",
        placeholder: "Enter a Referral Code",
        required: false,
        // disabled: true,
        width: 8,
        labelwidth: 24,
      },
      {
        no: 7,
        Label: "Address Line 1",
        name: "addressLine1",
        id: "addressLine1",
        type: "text",
        placeholder: "Enter a Address Line 1",
        required: false,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 8,
        Label: "Address Line 2",
        name: "addressLine2",
        id: "addressLine2",
        type: "text",
        placeholder: "Enter a Address Line 2",
        required: false,
        width: 12,
        labelwidth: 24,
      },
      {
        no: 9,
        Label: "PinCode",
        name: "pinCode",
        id: "pinCode  ",
        type: "number",
        placeholder: "Enter a PinCode",
        required: true,
        width: 6,
        labelwidth: 24,
      },
      {
        no: 10,
        Label: "City",
        name: "city",
        id: "city",
        type: "text",
        placeholder: "Enter a City",
        required: true,
        width: 6,
        labelwidth: 24,
      },
      {
        no: 11,
        Label: "State",
        name: "state",
        id: "state",
        type: "text",
        placeholder: "Enter a State",
        required: true,
        width: 6,
        labelwidth: 24,
      },
      {
        no: 12,
        Label: "Country",
        name: "country",
        id: "country",
        type: "text",
        placeholder: "Enter a Country",
        required: true,
        width: 6,
        labelwidth: 24,
      },
    ],
  },

  API: {
    login: {
      type: "POST",
      endpoint: "/admin/login",
    },
    signUp: {
      type: "POST",
      endpoint: "/user/signup",
    },

    getMe: {
      type: "GET",
      endpoint: "/admin/getMe",
    },

    // Brand Management API

    Brand_Management: {
      getAll: {
        type: "GET",
        endpoint: "/brand",
      },
      getOne: {
        type: "GET",
        endpoint: "/brand/:id",
      },
      add: {
        type: "POST",
        endpoint: "/brand",
      },
      update: {
        type: "PATCH",
        endpoint: "/brand/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/brand/",
      },
    },

    //Task Steps

    TaskSteps: {
      getAll: {
        type: "GET",
        endpoint: "/task-step",
      },
      getOne: {
        type: "GET",
        endpoint: "/task-step/:id",
      },
      add: {
        type: "POST",
        endpoint: "/task-step",
      },
      update: {
        type: "PATCH",
        endpoint: "/task-step/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/task-step/",
      },
    },

    // Banner API

    Banner: {
      getAll: {
        type: "GET",
        endpoint: "/banner",
      },
      getOne: {
        type: "GET",
        endpoint: "/banner/:id",
      },
      add: {
        type: "POST",
        endpoint: "/banner",
      },
      update: {
        type: "PATCH",
        endpoint: "/banner/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/banner/",
      },
    },

    Ad: {
      getAll: {
        type: "GET",
        endpoint: "/advertisement",
      },
      getOne: {
        type: "GET",
        endpoint: "/advertisement/:id",
      },
      add: {
        type: "POST",
        endpoint: "/advertisement",
      },
      update: {
        type: "PATCH",
        endpoint: "/advertisement/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/advertisement/",
      },
    },

    // userTask API

    UserTask: {
      getAll: {
        type: "GET",
        endpoint: "/userTask",
      },
      getOne: {
        type: "GET",
        endpoint: "/userTask/:id",
      },
      update: {
        type: "PATCH",
        endpoint: "/userTask/",
      },
      updateModal: {
        type: "PATCH",
        endpoint: "/userTask/:dataId",
      },
      bulkUpdate: {
        type: "PATCH",
        endpoint: "/userTask/bulkUpdate/:taskId",
      },
      taskCount: {
        type: "GET",
        endpoint: "/userTask/user-task-count",
      },
    },

    // Task Management API

    Task_Management: {
      getAll: {
        type: "GET",
        endpoint: "/task",
      },
      getOne: {
        type: "GET",
        endpoint: "/task/:id",
      },
      add: {
        type: "POST",
        endpoint: "/task",
      },
      update: {
        type: "PATCH",
        endpoint: "/task/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/task/",
      },
      notification: {
        type: "POST",
        endpoint: "notification/send-limited-time-notification",
      },
    },
    // User API

    Device_Management: {
      getAllDevice: {
        type: "GET",
        endpoint: "/blockDevice",
      },
      getAll: {
        type: "GET",
        endpoint: "/blockDevice/device-details",
      },
      update: {
        type: "POST",
        endpoint: "/blockDevice/bock-unblock-device",
      },
    },

    user: {
      getAll: {
        type: "GET",
        endpoint: "/user",
      },
      getOne: {
        type: "GET",
        endpoint: "/user/:id",
      },

      update: {
        type: "PATCH",
        endpoint: "/user/",
      },
      deletedUserList: {
        type: "GET",
        endpoint: "/user/deletedUserList",
      },
      generateCode: {
        type: "GET",
        endpoint: "user/generate-referral-code/:userId",
      },
    },

    scheduleNotification: {
      getAll: {
        type: "GET",
        endpoint: "/scheduleNotification",
      },
      add: {
        type: "POST",
        endpoint: "/scheduleNotification",
      },
      update: {
        type: "PATCH",
        endpoint: "/scheduleNotification/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/scheduleNotification/",
      },
    },

    // Role Management API

    Role_Management: {
      getAll: {
        type: "GET",
        endpoint: "/admin",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/:id",
      },
      add: {
        type: "POST",
        endpoint: "/admin",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/",
      },
      adminApproval: {
        type: "PATCH",
        endpoint: "/bulkPe/admin-approval/:dataId",
      },
    },

    referralUser: {
      getAll: {
        type: "GET",
        endpoint: "/referralUser",
      },
    },

    // Setting API
    setting: {
      general: {
        getAll: {
          type: "GET",
          endpoint: "/appConfig",
        },

        update: {
          type: "PATCH",
          endpoint: "/appConfig/:id",
        },
      },
      task_category: {
        getAll: {
          type: "GET",
          endpoint: "/taskCategory",
        },
        add: {
          type: "POST",
          endpoint: "/taskCategory",
        },
        update: {
          type: "PATCH",
          endpoint: "/taskCategory/",
        },
        delete: {
          type: "DELETE",
          endpoint: "/taskCategory/",
        },
      },
      testimonial: {
        getAll: {
          type: "GET",
          endpoint: "/testimonials",
        },
        add: {
          type: "POST",
          endpoint: "/testimonials",
        },
        update: {
          type: "PATCH",
          endpoint: "/testimonials/",
        },
        delete: {
          type: "DELETE",
          endpoint: "/testimonials/",
        },
      },

      faq: {
        getAll: {
          type: "GET",
          endpoint: "/FAQ",
        },
        add: {
          type: "POST",
          endpoint: "/FAQ",
        },
        update: {
          type: "PATCH",
          endpoint: "/FAQ/",
        },
        delete: {
          type: "DELETE",
          endpoint: "/FAQ/",
        },
      },
      terms: {
        getAll: {
          type: "GET",
          endpoint: "/TandC",
        },

        update: {
          type: "PATCH",
          endpoint: "/TandC/:dataId",
        },
      },
    },

    // Analytics API

    Analytics: {
      getGeneral: {
        type: "GET",
        endpoint: "/admin-analysis/general",
      },
      getTaskMatrix: {
        type: "GET",
        endpoint: "/admin-analysis/taskMatrix",
      },
      getUserData: {
        type: "GET",
        endpoint: "/admin-analysis/userChart",
      },
      getBrandForAnalytics: {
        type: "GET",
        endpoint: "/brand/get-all-for-analysis",
      },
      getTaskForAnalytics: {
        type: "GET",
        endpoint: "/task/get-all-for-analytics",
      },
      getCityForAnalytics: {
        type: "GET",
        endpoint: "/admin-analysis/distinct-city",
      },
    },

    trajection: {
      getAll: {
        type: "GET",
        endpoint: "/transaction/withdraw-request",
      },
      getHistory: {
        type: "GET",
        endpoint: "/transaction",
      },
      getPendingReqAmount: {
        type: "GET",
        endpoint: "transaction/total-pending-amount",
      },
    },
    affiliate: {
      getAll: {
        type: "GET",
        endpoint: "/userTask/affiliate-task-list",
      },
    },
    notification: {
      send: {
        type: "POST",
        endpoint: "/notification",
      },
    },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "Task Submission",
        name: "task",
        id: "task",
      },
      {
        no: 1,
        Label: "Withdrawal Request",
        name: "Withdrawal Request",
        id: "withdrawalRequest",
      },
      {
        no: 2,
        Label: "Transactions",
        name: "transaction",
        id: "transaction",
      },
      {
        no: 3,
        Label: "Referred User",
        name: "referredUser",
        id: "referredUser",
      },
      {
        no: 4,
        Label: "Referred User Task",
        name: "referredUserTask",
        id: "referredUserTask",
      },
      {
        no: 5,
        Label: "Affiliate History",
        name: "affiliateHistory",
        id: "affiliateHistory",
      },
    ],
    WITHDRAWAL: [
      {
        no: 1,
        Label: "Request",
        name: "request",
        id: "request",
      },
      {
        no: 2,
        Label: "History",
        name: "history",
        id: "history",
      },
    ],
    DEVICE: [
      {
        no: 1,
        Label: "Device",
        name: "deviceList",
        id: "deviceList",
      },
      {
        no: 2,
        Label: "Block Device",
        name: "blockDevice",
        id: "blockDevice",
      },
    ],
    USER_LISR: [
      {
        no: 1,
        Label: "Users",
        name: "list",
        id: "list",
      },
      {
        no: 2,
        Label: "Deleted User",
        name: "deletedList",
        id: "deletedList",
      },
    ],
    TASK_DETAILS_PAGE: [
      {
        no: 1,
        Label: "Task Steps",
        name: "steps",
        id: "steps",
      },
      {
        no: 2,
        Label: "Task Approval",
        name: "taskApproval",
        id: "taskApproval",
      },
    ],
    TASK: [
      {
        no: 1,
        Label: "All",
        name: "all",
        id: "all",
      },
      {
        no: 2,
        Label: "Reject",
        name: "reject",
        id: "reject",
      },
      {
        no: 3,
        Label: "Rework",
        name: "rework",
        id: "rework",
      },
      {
        no: 4,
        Label: "Accept",
        name: "accept",
        id: "accept",
      },
    ],
    SETTING: [
      {
        no: 1,
        Label: "General",
        name: "general",
        id: "general",
      },
      {
        no: 2,
        Label: "Task Category",
        name: "category",
        id: "category",
      },
      {
        no: 3,
        Label: "Testimonials",
        name: "testimonials",
        id: "testimonials",
      },
      {
        no: 3,
        Label: "Notification",
        name: "notification",
        id: "notification",
      },
      {
        no: 4,
        Label: "FAQ",
        name: "faq",
        id: "faq",
      },
      {
        no: 5,
        Label: "Spin Terms",
        name: "spin",
        id: "spin",
      },
      {
        no: 6,
        Label: "Referral Earning Terms & Conditions (%)",
        name: "referralEarningTermsAndConditions",
        id: "referralEarningTermsAndConditions",
      },
      {
        no: 7,
        Label: "Privacy Policy",
        name: "policy",
        id: "policy",
      },
      {
        no: 8,
        Label: "Terms and Conditions",
        name: "terms",
        id: "terms",
      },
      {
        no: 9,
        Label: "Work Agreement",
        name: "agreement",
        id: "agreement",
      },
    ],
  },
};
export default CONSTANTS;
