import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";

const ReferredUserTask = () => {
  const { id } = useParams();
  return (
    <>
      <>
        <Row>
          <CRUDComponent
            // reload={reload}
            GET={{
              API: CONSTANTS.API.UserTask.getAll,
              extraQuery: { isReferredData: 1, userId: id },
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    name: data?.user?.name,
                    mobile: data?.user?.mobile,
                    taskName: data?.task?.name,
                    taskAmount: data?.task?.completionAmount,
                    commissionAmount: `${data?.percentageReferralUserEarnings?.[0]?.amount} (${data?.percentageReferralUserEarnings?.[0]?.percentage}%)`,
                  };
                });
              },
              column: CONSTANTS.TABLE.REFERRED_USER_TASK,
            }}
            isSearch
          />
        </Row>
      </>
    </>
  );
};

export default memo(ReferredUserTask);
