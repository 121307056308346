import { Card, DatePicker, Row, Select, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import LineChart from "./LineChart";
import CONSTANTS from "../../util/constant/CONSTANTS";
import useHttp from "../../hooks/use-http";
import { fillMissingData, getSelectionOption } from "../../util/functions";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ProcessCard = ({ plan = {} }) => {
  const API = useHttp();
  const [serviceDetails, setUtilizations] = useState([]);
  const [analyticsVaiables, setAnalyticsVaiables] = useState([]);
  const [selected, setSelected] = useState("Daily");
  const [dateRange, setdateRange] = useState({
    startDate: dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    API.sendRequest(
      CONSTANTS.API.Analytics.getUserData,
      (res) => {
        const fillDates = fillMissingData(
          res?.data,
          dateRange?.startDate,
          dateRange?.endDate,
          selected
        );
        setUtilizations(fillDates);
        setAnalyticsVaiables([
          {
            id: 1,
            label: "Total User",
            style: {
              color: "#1677ff",
            },
            value: res?.data?.reduce(
              (sum, current) => sum + current.userCount,
              0
            ),
            infoMessage:
              "Average Process CPU Usage Count between the selected date range.",
          },
        ]);
      },
      {
        dateFilter: selected,
        ...dateRange,
      }
    );
  }, [selected, dateRange]);

  const handleChange = (value) => {
    setSelected(value);
  };

  const onDateChange = (value) => {
    if (!(value?.length > 0)) {
      setdateRange(null);
      return;
    }

    setdateRange({
      startDate: value?.[0]?.format("YYYY-MM-DD"),
      endDate: value?.[1]?.format("YYYY-MM-DD"),
    });
  };

  return (
    <Card className="w-full">
      <Row className="justify-between items-center mb-5">
        <Text>User Data</Text>

        <div className="flex  justify-start items-center gap-3">
          <Text className="hidden  md:block">Date Range</Text>
          <RangePicker
            allowClear={false}
            defaultValue={[
              dayjs(dateRange?.startDate),
              dayjs(dateRange?.endDate),
            ]}
            onChange={onDateChange}
            disabledDate={disabledDate}
          />
          <Select
            defaultValue={selected}
            style={{ width: 120 }}
            onChange={handleChange}
            options={getSelectionOption()}
          />
        </div>
      </Row>
      <div className="grid mt-3 mb-10 mobile:grid-cols-3 grid-cols-5 gap-y-4">
        {analyticsVaiables.length > 0 &&
          analyticsVaiables?.map((el) => (
            <div
              key={el.id}
              className="flex flex-col border-r [&:last-child]:border-none items-center"
            >
              <p className={` text-xl lg:text-2xl`} style={el?.style || {}}>
                {el.value}
              </p>
              <p className="text-grayColor text-xs flex  lg:text-sm text-center">
                {el.label}
                <Tooltip className="cursor-pointer" title={el?.infoMessage}>
                  <div className="mt-0.5 ">{/* <InfoIcon /> */}</div>
                </Tooltip>
              </p>
            </div>
          ))}
      </div>
      <LineChart
        isLoading={API.isLoading}
        data={serviceDetails || []}
        // annotations={CONSTANTS.CPU_ANNOTATION}
      />
    </Card>
  );
};

export default ProcessCard;
