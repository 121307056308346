import React, { memo, useState } from "react";
import { Row, Typography } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { PERMISSION } from "../../../../util/functions";
import ModalFormCreator from "../../../../component/common/ModalFormCreator";
import useHttp from "../../../../hooks/use-http";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const ReferredUser = ({ accessPermission }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openResonModal, setOpenResonModal] = useState(null);
  const [reload, setReload] = useState(false);

  const api = useHttp();

  const OnFormSubmitBtn = (res) => {
    const payload = {
      isBlocked: !!openResonModal,
      blockReason: res?.blockReason ?? "",
    };
    const UpdateAPIEnd = {
      ...CONSTANTS?.API.user.update,
    };
    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${openResonModal ?? res}`;
    api.sendRequest(
      UpdateAPIEnd,
      () => {
        setOpenResonModal(null);
        setReload((pre) => !pre);
      },
      payload,
      `User ${payload?.isBlocked ? "Blocked" : "Unblock"} Successfully`
    );
  };

  return (
    <>
      <>
        <Row>
          <CRUDComponent
            reload={reload}
            GET={{
              API: CONSTANTS.API.referralUser.getAll,
              extraQuery: { referrerId: id },
              DataModifier: (res, API, Setrefresh, alldata) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    no: data?.id,
                    name: data?.user?.name,
                    mobile: data?.user?.mobile,
                    email: data?.user?.email,
                    createdAt: data?.user?.createdAt,
                    deviceId: data?.user?.deviceId,
                    blockReason: data?.user?.blockReason,
                    walletBalance: data?.user?.walletBalance,
                    commisionProgress:
                      alldata?.userUpperLimitForReferrenceEarning ? (
                        data?.amount <
                        Number(alldata?.userUpperLimitForReferrenceEarning) ? (
                          <div className="flex items-center space-x-1 text-gray-700">
                            <Typography.Text
                              className={`font-semibold text-base ${
                                (data?.amount /
                                  alldata?.userUpperLimitForReferrenceEarning) *
                                  100 <
                                  50 && "text-red-500"
                              }`}
                            >
                              ₹ {data?.amount}
                            </Typography.Text>
                            <Typography.Text className="text-gray-500">
                              /
                            </Typography.Text>
                            <Typography.Text className="text-gray-500">
                              ₹ {alldata?.userUpperLimitForReferrenceEarning}
                            </Typography.Text>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-1">
                            <Typography.Text className="text-base text-green-600">
                              ₹ {data?.amount}
                            </Typography.Text>
                            <RiVerifiedBadgeFill
                              size={25}
                              className="text-green-500 text-xl"
                            />
                          </div>
                        )
                      ) : (
                        "-"
                      ),
                    view: {
                      id: data?.id,
                      onClick: (id) => {
                        navigate(`/app/user/${data?.userId}`);
                      },
                    },
                    isBlocked: {
                      checked: !data?.user?.isBlocked,
                      id: data?.user?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        val ? setOpenResonModal(id) : OnFormSubmitBtn(id);
                      },
                    },
                  };
                });
              },
              column: CONSTANTS.TABLE.REFERRED_HISTORY,
            }}
            isSearch
          />
        </Row>
        <ModalFormCreator
          loading={api.isLoading}
          open={openResonModal}
          onCreate={OnFormSubmitBtn}
          onCancel={() => {
            setOpenResonModal(null);
          }}
          menuFields={CONSTANTS.FORM_FIELD.USER_BLOCK_FORM}
          name={"Reason"}
          SubmitName={"Submit"}
        />
      </>
    </>
  );
};

export default memo(ReferredUser);
