import React, { memo, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import CRUDComponent from "../../../component/common/CRUD-Component";
import { userExportData } from "../../../util/exportData";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import { PERMISSION } from "../../../util/functions";
import { RxCrossCircled } from "react-icons/rx";
import { GrInProgress } from "react-icons/gr";
import ModalFormCreator from "../../../component/common/ModalFormCreator";

const UserList = ({ accessPermission }) => {
  const api = useHttp();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [openResonModal, setOpenResonModal] = useState(null);

  const OnFormSubmitBtn = (res) => {
    const payload = {
      isBlocked: !!openResonModal,
      blockReason: res?.blockReason ?? "",
    };
    const UpdateAPIEnd = {
      ...CONSTANTS?.API.user.update,
    };
    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${openResonModal ?? res}`;
    api.sendRequest(
      UpdateAPIEnd,
      () => {
        setOpenResonModal(null);
        setReload((pre) => !pre);
      },
      payload,
      `User ${payload?.isBlocked ? "Blocked" : "Unblock"} Successfully`
    );
  };
  return (
    <>
      <CRUDComponent
        title="User"
        reload={reload}
        exportData={userExportData}
        accessPermission={accessPermission}
        GET={{
          API: CONSTANTS.API.user.getAll,
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((data) => {
              return {
                ...data,
                no: `U${data?.id.toString().padStart(7, "0")}`,
                view: {
                  id: data?.id,
                  onClick: (id) => {
                    navigate(`/app/user/${id}`);
                  },
                },
                userImage: { image: data?.image, name: data?.name },
                city: data?.city,
                // dob: convertUTCToLocal(data?.DOB, "DD-MM-YYYY"),
                // toggle: (
                //   <Switch
                //     size={"default"}
                //     onChange={(checked) => {
                //       checked
                //         ? setOpenResonModal(data?.id)
                //         : OnFormSubmitBtn(data?.id);
                //     }}
                //     checked={data?.isBlocked}
                //     disabled={PERMISSION[accessPermission] === "READ"}
                //   />
                // ),
                toggle: {
                  checked: !data?.isBlocked,
                  id: data?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    val ? setOpenResonModal(id) : OnFormSubmitBtn(id);
                  },
                },
                identify: (
                  <>
                    {data?.isGovermentIdVerified === "Verified" ? (
                      <CheckCircleOutlined className="text-2xl text-[#007BFF]" />
                    ) : data?.isGovermentIdVerified === "Failed" ? (
                      <RxCrossCircled className="text-2xl text-red-500" />
                    ) : (
                      <GrInProgress className="text-2xl text-slate-500" />
                    )}
                  </>
                ),
                isAffiliateD: data?.isAffiliateEnable ? "Yes" : "No",
                isBlockedD: data?.isBlocked ? "Yes" : "No",
                isIdentifyD: data?.isGovermentIdVerified,
                affiliate: {
                  checked: !data?.isAffiliateEnable,
                  id: data?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    const UpdateAPIEnd = {
                      ...CONSTANTS?.API.user.update,
                    };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    API.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { isAffiliateEnable: val },
                      `Affiliate ${
                        val ? "Activate" : "Deactivate"
                      } Successfully`
                    );
                  },
                },
                referAndEarnEnable: {
                  checked: !data?.isReferAndEarnEnable,
                  id: data?.id,
                  isDisable: PERMISSION[accessPermission] === "READ",
                  onClick: (id, val) => {
                    const UpdateAPIEnd = {
                      ...CONSTANTS?.API.user.update,
                    };
                    UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    API.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { isReferAndEarnEnable: val },
                      `refer & Earn ${
                        val ? "Activate" : "Deactivate"
                      } Successfully`
                    );
                  },
                },
              };
            });
          },
          column: CONSTANTS.TABLE.USERS,
        }}
        isSearch
        UPDATE={{
          API: CONSTANTS.API.user.update,
          isFormData: true,
          message: "Edit User successfully",
          modaltitle: "Edit User",
          modalFields: CONSTANTS.FORM_FIELD.EDIT_USER_MODAL,
          // payloadModifier: (res) => res,
        }}
      />
      <ModalFormCreator
        loading={api.isLoading}
        open={openResonModal}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setOpenResonModal(null);
        }}
        menuFields={CONSTANTS.FORM_FIELD.USER_BLOCK_FORM}
        name={"Reason"}
        SubmitName={"Submit"}
      />
    </>
  );
};

export default memo(UserList);
