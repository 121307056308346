import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ALL_ROUTES from "./util/Route";
import { DEFAUlT_PERMISSION, generateRoutes, isLogin } from "./util/functions";
import useHttp from "./hooks/use-http";
import CONSTANTS from "./util/constant/CONSTANTS";
import { Spin } from "antd";
function App() {
  const [admindetails, setAdmindetails] = useState(null)
  const api = useHttp()
  useEffect(() => {
    if (!isLogin()) {
      // window.location.assign(loginRoot);
      return;
    }

    const GET_MY_PROFILE_API = { ...CONSTANTS.API.getMe }

    api.sendRequest(GET_MY_PROFILE_API, (res) => {
      const removeSettingModule = res?.data?.access
        ?.split(",")
        .filter((item) => item !== "setting-0")
        .join(",");

      setAdmindetails({ ...res?.data, access: removeSettingModule });
    });


  }, [isLogin])


  const router = createBrowserRouter(generateRoutes(ALL_ROUTES({
    admindetails,
  }), admindetails?.access || DEFAUlT_PERMISSION));
  return (!admindetails && isLogin()) ? <Spin size="large" tip="Loading..." className="!top-[20rem]" ><div /></Spin> : <RouterProvider router={router} />;
}

export default App;
