import { Card, Row } from "antd";
import React, { useMemo } from "react";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { PERMISSION } from "../../../util/functions";
import { useParams } from "react-router-dom";

const TaskSteps = ({ accessPermission }) => {
  const { id } = useParams();

  const memoizedExtraQuery = useMemo(() => {
    return {
      taskId: id,
    };
  }, [id]);

  function getWaitingFormate(minutes) {
    if (minutes % 1440 === 0) {
      return { timeFormate: "Days", waitingTimesStep: minutes / 1440 }; // 1440 minutes = 1 day
    } else if (minutes % 60 === 0) {
      return { timeFormate: "Hours", waitingTimesStep: minutes / 60 }; // 60 minutes = 1 hour
    }
    return { timeFormate: "Minutes", waitingTimesStep: minutes }; // Default format
  }

  function getFormatedTime(minutes) {
    if (minutes === 0) {
      return minutes + " Days"; // 1440 minutes = 1 day
    } else if (minutes % 1440 === 0) {
      return minutes / 1440 + " Days"; // 1440 minutes = 1 day
    } else if (minutes % 60 === 0) {
      return minutes / 60 + " Hours"; // 60 minutes = 1 hour
    }
    return minutes + " Minutes"; // Default format
  }

  return (
    <div>
      <Card className="my-5">
        <Heading>Task Steps</Heading>
        <Row>
          <CRUDComponent
            title="steps"
            accessPermission={accessPermission}
            isFormModifier={true}
            GET={{
              API: CONSTANTS.API.TaskSteps.getAll,
              extraQuery: memoizedExtraQuery,
              DataModifier: (res, API, Setrefresh) => {
                return res?.map((data) => {
                  return {
                    ...data,
                    stepRank: data?.stepNumber,
                    ...getWaitingFormate(data?.waitingTime),
                    waitingTimeBTwoStep: getFormatedTime(data?.waitingTime),
                    stepNumberField: {
                      data: data?.stepNumber,
                      onBlur: (Rank) => {
                        if (!isNaN(Number(Rank))) {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.TaskSteps.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${data?.id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { stepNumber: Number(Rank) },
                            `Step Rank Updated Successfully`
                          );
                        }
                      },
                    },
                    isSubNeeded: {
                      checked: !data?.isPOWNeeded,
                      id: data?.id,
                      isDisable: PERMISSION[accessPermission] === "READ",
                      onClick: (id, val) => {
                        const UpdateAPIEnd = {
                          ...CONSTANTS?.API.TaskSteps.update,
                        };
                        UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                        API.sendRequest(
                          UpdateAPIEnd,
                          () => {
                            Setrefresh((prev) => !prev);
                          },
                          { isPOWNeeded: val },
                          `Step Updated Successfully`
                        );
                      },
                    },
                  };
                });
              },
              column: CONSTANTS.TABLE.TASKSTEP,
            }}
            // isSearch
            DELETE={{
              API: CONSTANTS.API.TaskSteps.delete,
              message: "Deleted Step Successfully",
            }}
            UPDATE={{
              API: CONSTANTS.API.TaskSteps.update,
              message: "Edit Step successfully",
              modaltitle: "Edit Task Step",
              modalFields: CONSTANTS.FORM_FIELD.TASK_STEP,
              payloadModifier: (res) => {
                const updatedRes = {
                  ...res,
                  waitingMessage: res?.waitingMessage ?? "",
                  errorMessage: res?.errorMessage ?? "",
                  notificationTitle: res?.notificationTitle ?? "",
                  notificationBody: res?.notificationBody ?? "",
                };

                if (res.timeFormate === "Hours") {
                  updatedRes.waitingTime = res.waitingTimesStep * 60;
                } else if (res.timeFormate === "Days") {
                  updatedRes.waitingTime = res.waitingTimesStep * 1440;
                } else updatedRes.waitingTime = res.waitingTimesStep;

                delete updatedRes.timeFormate;
                delete updatedRes.waitingTimesStep;

                return updatedRes;
              },
            }}
            CREATE={{
              API: CONSTANTS.API.TaskSteps.add,
              message: "Created Step successfully",
              modaltitle: "Add New Task Step",
              modalFields: CONSTANTS.FORM_FIELD.TASK_STEP,
              payloadModifier: (res) => {
                const updatedRes = {
                  ...res,
                  taskId: id,
                  waitingMessage: res?.waitingMessage ?? "",
                  errorMessage: res?.errorMessage ?? "",
                  notificationTitle: res?.notificationTitle ?? "",
                  notificationBody: res?.notificationBody ?? "",
                };

                if (res.timeFormate === "Hours") {
                  updatedRes.waitingTime = res.waitingTimesStep * 60;
                } else if (res.timeFormate === "Days") {
                  updatedRes.waitingTime = res.waitingTimesStep * 1440;
                } else updatedRes.waitingTime = res.waitingTimesStep;

                delete updatedRes.timeFormate;
                delete updatedRes.waitingTimesStep;

                return updatedRes;
              },
            }}
          />
        </Row>
      </Card>
    </div>
  );
};

export default TaskSteps;
