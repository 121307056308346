import React, { memo } from "react";
import { Row } from "antd";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { convertUTCToLocal } from "../../../../util/functions";

const Task = ({ id }) => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <CRUDComponent
          GET={{
            API: CONSTANTS.API.UserTask.getAll,
            extraQuery: { userId: id },
            DataModifier: (res, API, Setrefresh) => {
              return res?.map((data) => {
                return {
                  ...data,
                  no: data?.id,
                  task: data?.task?.name,
                  applyDate: convertUTCToLocal(data?.createdAt),
                  submissionDate: data?.submissionDateTime
                    ? convertUTCToLocal(data?.submissionDateTime)
                    : "In-Progress",
                  amount: data?.task?.completionAmount,
                  view: {
                    id: data?.taskId,
                    onClick: (id) => {
                      navigate(`/app/task/${id}`);
                    },
                  },
                };
              });
            },
            column: CONSTANTS.TABLE.USER_TASK,
          }}
          isSearch
        />
      </Row>
    </>
  );
};

export default memo(Task);
