import { Card, Input, Row } from "antd";
import React, { useState } from "react";
import Heading from "../../../component/common/Heading";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { PERMISSION } from "../../../util/functions";

const Banner = ({ admindetails, permissionId }) => {
  const [extraQuery, setExtraQuery] = useState({ sort: "ranking" });
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  return (
    <>
      <div>
        <Card className="my-5">
          <Heading>Banner List</Heading>
          <Row>
            <CRUDComponent
              title="Banner"
              // exportData={brandExportData}
              // accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.Banner.getAll,
                extraQuery,
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((data) => {
                    return {
                      ...data,
                      ranking: {
                        data: data?.ranking,
                        onBlur: (Rank) => {
                          if (!isNaN(Number(Rank))) {
                            const UpdateAPIEnd = {
                              ...CONSTANTS?.API.Banner.update,
                            };
                            UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${data?.id}`;
                            API.sendRequest(
                              UpdateAPIEnd,
                              () => {
                                Setrefresh((prev) => !prev);
                              },
                              { ranking: Number(Rank) },
                              `Ranking Update Successfully`
                            );
                          }
                        },
                      },
                      rankingNumber: data?.ranking,
                      bannerType: data?.bannerType,
                      image: data?.image,
                      link: data?.link,
                      isBlocked: {
                        checked: !data?.isBlocked,
                        id: data?.id,
                        isDisable: PERMISSION[accessPermission] === "READ",
                        onClick: (id, val) => {
                          const UpdateAPIEnd = {
                            ...CONSTANTS?.API.Banner.update,
                          };
                          UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                          API.sendRequest(
                            UpdateAPIEnd,
                            () => {
                              Setrefresh((prev) => !prev);
                            },
                            { isBlocked: val },
                            `Banner ${val ? "Blocked" : "Unblock"} Successfully`
                          );
                        },
                      },
                    };
                  });
                },
                column: CONSTANTS.TABLE.BANNER,
              }}
              // isSearch
              DELETE={{
                API: CONSTANTS.API.Banner.delete,
                message: "Deleted Banner Successfully",
              }}
              UPDATE={{
                API: CONSTANTS.API.Banner.update,
                isFormData: true,
                message: "Edit Banner successfully",
                modaltitle: "Edit Banner",
                modalFields: CONSTANTS.FORM_FIELD.BANNER_EDIT_MODAL,
                // payloadModifier: (res) => res,
              }}
              CREATE={{
                API: CONSTANTS.API.Banner.add,
                isFormData: true,
                message: "Created Banner successfully",
                modaltitle: "Add New Banner",
                modalFields: CONSTANTS.FORM_FIELD.BANNER_MODAL,
                // payloadModifier: (res) => res,
              }}
            />
          </Row>
        </Card>
      </div>
    </>
  );
};

export default Banner;
